<template>
  <div>
    <div class="mb-4">
      <a-table :columns="columns"
        :dataSource="data"
        :pagination="false"
        :class="$style.table"
        :loading="load"
      >
        <template slot="ticket" slot-scope="text">
          <strong>{{text}}</strong>
        </template>
        <template slot="location" slot-scope="text">
          <strong class="text-blue">{{ parseFloat(text).toFixed(4) }}</strong>
        </template>
        <template slot="type" slot-scope="text">
          <strong class="text-secondary" v-if="text == 'Entrada'"><b-icon icon="door-open-fill"></b-icon> {{text}}</strong>
          <strong class="text-warning" v-if="text == 'Salida'"><b-icon icon="door-closed-fill"></b-icon> {{text}}</strong>
          <strong class="text-success" v-if="text == 'Neto'"><b-icon icon="bag-plus-fill"></b-icon> {{text}}</strong>
        </template>
        <template slot="status" slot-scope="text">
          <strong class="text-success" v-if="text == 'Registrado'">
            {{text}}
          </strong>
          <strong class="text-warning" v-else-if="text == 'Pendiente'">
            {{text}}
          </strong>
          <strong v-else>
            {{text}}
          </strong>
        </template>
        <template slot="value" slot-scope="text">
          <span class="font-weight-bold">
            {{parseFloat(text).toFixed(4)}}
          </span>
        </template>
        <template slot="actions" slot-scope="data">
          <b-icon-check-circle-fill variant="success" v-show="data.blocked_check === true"/>
          <responsive-button variant="primary"
            pill size="sm" responsive="md"
            text="Editar" icon="pencil-square"
            @ClickEvent="showModal('edit', data)"
            v-show="data.weight_type != 'Neto' && data.status_save === true && data.blocked_check === false"
          />
        </template>
      </a-table>
    </div>
  </div>
</template>
<script>
const columns = [
  {
    title: 'Ticket',
    dataIndex: 'ticket',
    class: 'text-center bg-transparent text-gray-6',
    scopedSlots: { customRender: 'ticket' },
  },
  {
    title: 'Pesaje (Toneladas)',
    dataIndex: 'weight',
    class: 'text-center bg-transparent text-gray-6',
    scopedSlots: { customRender: 'location' },
  },
  {
    title: 'Tipo',
    dataIndex: 'weight_type',
    key: 'weight_type',
    class: 'text-center text-gray-6 bg-transparent',
    scopedSlots: { customRender: 'type' },
  },
  {
    title: 'Estatus',
    dataIndex: 'status',
    key: 'status',
    class: 'text-center text-gray-6 bg-transparent',
    scopedSlots: { customRender: 'status' },
  },
  {
    title: 'Fecha',
    dataIndex: 'dated_at',
    class: 'text-center bg-transparent text-gray-6',
  },
  {
    title: 'Horario',
    dataIndex: 'hour',
    class: 'text-center bg-transparent text-gray-6',
  },
  {
    title: 'Acciones',
    dataIndex: '',
    class: 'text-center bg-transparent text-gray-6',
    scopedSlots: { customRender: 'actions' },
  },
]
export default {
  name: 'WeightTable',
  props: {
    data: {
      type: [Object, Array],
      required: true,
    },
    load: {
      type: [Boolean],
      required: true,
    },
  },
  data() {
    return {
      columns,
    }
  },
  methods: {
    showModal(action, data = {}) {
      this.$emit('Modal', action, data)
    },
  },
}
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
