<template>
  <div>
    <div class="air__utils__heading">
      <h5>Pesaje del Servicio de Transporte: {{carrierID}}</h5>
    </div>
    <my-breadcrumbs :routes="routes" class="my-4"/>
    <!-- MODAL-->
    <b-modal title="Información de Pesaje"  ref="modal-weighing" size="lg">
      <b-container fluid>
        <a-form-model layout="horizontal" ref="rule-Form" :model="weightRegistryForm" :rules="weighingRules">
          <!-- Visualización de la información del pesaje -->
          <h5 class="text-secondary text-left font-weight-bold mt-3">Información de Pesaje</h5>
          <hr class="bg-primary">
          <a-row align="middle" justify="center" class="ml-4 mr-4">
            <a-col :sm="{ span: 24}" :md="{ span:12}">
              <a-form-model-item label="Ticket" ref="ticket" prop="ticket">
                <a-input v-model="weightRegistryForm.ticket" class="mr-2"/>
              </a-form-model-item>
            </a-col>
            <a-col :sm="{ span: 24}" :md="{ span:12}">
              <a-form-model-item label="Tipo de Pesaje" ref="type" prop="type">
                <a-select v-model="weightType" placeholder="Seleccionar" class="ml-2" disabled>
                  <a-select-option  v-for="option in weightTypes" :key="option.id" :value="option.id" >
                    {{option.name}}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row align="middle" justify="center" class="ml-4">
            <a-col :sm="{ span: 24}" :md="{ span:12}">
              <a-form-model-item label="Pesaje en báscula" ref="weight" prop="weight">
                <a-input-number v-model="weightRegistryForm.weight" size="large" :min="0" :precision="2"
                  decimalSeparator="." step=".1" :max="100000"
                />
              </a-form-model-item>
            </a-col>
            <a-col :sm="{ span: 24}" :md="{ span:12}">
              <a-form-model-item label="Unidad de Medida" class="mx-1" ref="u_m_s_id" prop="u_m_s_id">
<!--                <a-select v-model="weightRegistryForm.u_m_s_id" placeholder="Seleccionar" disabled>-->
                <a-select v-model="weightRegistryForm.u_m_s_id" disabled>
                  <a-select-option  v-for="option in ums" :key="option.jsonApi.id" :value="option.jsonApi.id" >
                    {{option.name}}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row align="middle" justify="center" style="background-color: black;">
            <a-col :sm="{ span: 18, offset: 3 }">
              <div style="background-color: black;padding-bottom: 0.5rem;">
                <v-sevenseg color-on="green" color-off="black" :width="lengthWeighingValue*70" :digits="lengthWeighingValue" :value="WeighingValue" resize slant=8 />
              </div>
            </a-col>
          </a-row>
          <!-- Visualización de la información del pesaje -->
          <h5 class="text-secondary text-left font-weight-bold mt-4">Información Adicional</h5>
          <hr class="bg-primary">
          <a-row align="middle" justify="center" v-show="!editFlag">
            <a-col :sm="{ span: 24}" :md="{ span:12}">
              <a-form-model-item label="Fecha de Recepción" class="mx-1" ref="date" prop="date">
                <a-date-picker format="DD/MM/YYYY" v-model="weightRegistryForm.date" block />
              </a-form-model-item>
            </a-col>
            <a-col :sm="{ span: 24}" :md="{ span:12}">
              <a-form-model-item label="Hora de Recepción" class="mx-1" ref="hour" prop="hour">
                <a-time-picker v-model="weightRegistryForm.hour" format="HH:mm" />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row align="middle" justify="center" v-show="!editFlag">
            <a-col span="24">
              <a-form-model-item label="Nombre del Vigilante" ref="vigilant_name" prop="vigilant_name">
                <a-input v-model="weightRegistryForm.vigilant_name"/>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row align="middle" justify="center" class="ml-0">
            <a-col :span="24">
              <a-form-model-item label="Detalles">
                <a-textarea v-model="weightRegistryForm.details" placeholder="Detalles" :rows="4" allow-clear/>
              </a-form-model-item>
            </a-col>
          </a-row>
          <!-- BOTONES -->
          <a-row  align="middle" justify="center" class="mt-0" v-show="!load">
            <a-col :span="12" offset="6">
              <responsive-button block variant="primary" pill text="Guardar" responsive="md"
                icon="archive-fill" size="md" @ClickEvent="onSubmit"/>
            </a-col>
          </a-row>
          <!-- Spinner -->
          <my-spinner :load="load"/>
        </a-form-model>
      </b-container>
      <template slot="modal-footer">
        <simple-modal-footer @ClickEvent="CloseWeighingModal"/>
      </template>
    </b-modal>
    <!-- MODAL-->
    <div class="row">
      <div class="col">
        <!--     PESAJE   -->
        <div class="card">
          <div class="card-body text-center">
            <h5 class="text-secondary text-left font-weight-bold mt-3">Información de la Carga</h5>
            <hr class="bg-primary">
            <b-container fluid>
              <div class="row mb-2">
                <div class="col-2 offset-8">
                  <responsive-button pill block text="Regresar" variant="warning" responsive="lg"
                    icon="arrow-left-circle-fill" :to="{ name: 'reception_service_plannings', params: { folio : $store.state.servicePlannings.servicePlanningId }}" size="sm" v-show="showNew"/>
                </div>
                <div class="col-2">
                  <responsive-button pill block text="Registrar pesaje" class="bg-real-blue" responsive="lg"
                  icon="calculator" @ClickEvent="OpenWeighingModal" size="sm" v-show="showNew"/>
                </div>
                <div class="offset-10 col-2">
                  <responsive-button pill block text="Cerrar pesaje" variant="success" responsive="lg"
                                     icon="calculator" @ClickEvent="closeWeight" size="sm" v-show="!showNew && !blocked_check_record"/>
                </div>
              </div>
              <!-- Tabla con la información de pesaje -->
              <weight-table :data="dataSet" :load="load" @Modal="OpenEditableModal"/>
              <!-- Tabla con la información de pesaje -->
              <a-form-model layout="horizontal" :model="weightForm" ref="weighing2-Form">
                <a-row align="middle" justify="center">
                  <a-col class="pr-2 border-right border-primary border-w-3" :sm="{ span: 24}" :md="{ span:12}" >
                    <h5 class="text-secondary text-left font-weight-bold mt-0">Información de Entrada</h5>
                    <hr class="bg-primary">
                    <!-- Observaciones  -->
                    <a-row align="middle" justify="center">
                      <a-col span="24">
                        <a-form-model-item label="Observación">
                          <a-textarea v-model="weightForm.entry_details" placeholder="Detalles" :rows="5" allow-clear disabled/>
                        </a-form-model-item>
                      </a-col>
                    </a-row>
                    <!-- Nombre de Vigilante  -->
                    <a-row align="middle" justify="center">
                      <a-col span="24">
                        <a-form-model-item label="Nombre del Vigilante">
                          <a-input v-model="weightForm.entry_vigilant_name" disabled/>
                        </a-form-model-item>
                      </a-col>
                    </a-row>
                  </a-col>
                  <a-col class="pl-2" :sm="{ span: 24}" :md="{ span:12 }">
                    <h5 class="text-secondary text-left font-weight-bold mt-0">Información de Salida</h5>
                    <hr class="bg-primary">
                    <!-- Observaciones  -->
                    <a-row align="middle" justify="center">
                      <a-col span="24">
                        <a-form-model-item label="Observación">
                          <a-textarea v-model="weightForm.exit_details" placeholder="Detalles" :rows="5" allow-clear disabled/>
                        </a-form-model-item>
                      </a-col>
                    </a-row>
                    <!-- Nombre de Vigilante  -->
                    <a-row align="middle" justify="center">
                      <a-col span="24">
                        <a-form-model-item label="Nombre del Vigilante">
                          <a-input v-model="weightForm.exit_vigilant_name" disabled/>
                        </a-form-model-item>
                      </a-col>
                    </a-row>
                  </a-col>
                </a-row>
<!--                <a-row align="middle" justify="center" class="mt-0">-->
<!--                  <h5 class="text-secondary text-left font-weight-bold mt-3">Cargar de Evidencia de Pesaje</h5>-->
<!--                  <hr class="bg-primary">-->
<!--                  <div class="col-12">-->
<!--                    <a-form-item>-->
<!--                      <a-upload-dragger>-->
<!--                        <p class="ant-upload-drag-icon">-->
<!--                          <a-icon type="inbox" />-->
<!--                        </p>-->
<!--                        <p class="ant-upload-text">De click o arrastre la evidencia de pesaje a esta área</p>-->
<!--                        <p class="ant-upload-hint">-->
<!--                          Soporte para una carga única o masiva.-->
<!--                        </p>-->
<!--                      </a-upload-dragger>-->
<!--                    </a-form-item>-->
<!--                  </div>-->
<!--                </a-row>-->
                <!-- BOTONES -->
              </a-form-model>
            </b-container>
            <!-- Formulario y Báscula -->
          </div>
        </div>
        <!--     PESAJE   -->
      </div>
    </div>
  </div>
</template>

<script>
import 'moment/locale/es'
import moment from 'moment'
import { Modal, notification } from 'ant-design-vue'
import WeightTable from './components/weightTable.vue'
import VSevenseg from '@/components/sevenSeg/VSevenseg'
import { getGeneralMixin } from '@/mixins/getGeneralMixin'
export default {
  name: 'weighing',
  mixins: [
    getGeneralMixin,
  ],
  components: {
    VSevenseg,
    WeightTable,
  },
  data() {
    return {
      // Contants
      UM_SAPCODE: 'TON',
      carrierID: this.$route.params.carrierID,
      editFlag: false,
      //  ----- Forms -----//
      weightTypes: [
        { id: 'Entrada', name: 'Entrada' },
        { id: 'Salida', name: 'Salida' },
      ],
      ums: [],
      // pesaje
      ticketStore: '',
      weightRegistryForm: {
        u_m_s_id: undefined,
        ticket: '',
        date: moment(),
        hour: moment(),
        weight: 0,
        type: 'Entrada',
        vigilant_name: '',
        details: '',
        save_check: true,
      },
      weightForm: {
        entry_details: '',
        entry_vigilant_name: '',
        exit_details: '',
        exit_vigilant_name: '',
      },
      dataSet: [],
      weightData: [],
      weighingRules: {
        ticket: [
          { required: true, message: 'El campo Ticket de Pesaje es obligatorio.', trigger: 'blur' },
        ],
        date: [
          { required: true, message: 'El campo Fecha de Pesaje es obligatorio.', trigger: 'blur' },
        ],
        hour: [
          { required: true, message: 'El campo Horario de Pesaje es obligatorio.', trigger: 'blur' },
        ],
        weight: [
          { required: true, message: 'El campo de Pesaje es obligatorio.', trigger: 'blur' },
        ],
        vigilant_name: [
          { required: true, message: 'El campo Nombre del Vigilante es obligatorio.', trigger: 'blur' },
        ],
      },
      load: false,
      id: '0',
      weightType: 'Entrada',
      relationships: {
        servicePlanningsCarriers: {
          data: { id: 0, type: 'servicePlanningsCarriers' },
        },
        ums: {
          data: { id: 0, type: 'ums' },
        },
      },
      resource: 'weightRegistries',
      entry_save_check: false,
      exit_save_check: false,
      showNew: true,
      blocked_check_record: false,
      // ******************************
      // ******************************
      saveAction: '',
    }
  },
  computed: {
    lengthWeighingValue() {
      return this.weightRegistryForm.weight.toString().length
    },
    WeighingValue() {
      return this.weightRegistryForm.weight.toString()
    },
    surveillanceCheckListCodesArray() {
      return this.surveillanceCheckListCodes.split(' ')
    },
    laboratoryCheckListCodesArray() {
      return this.laboratoryCheckListCodes.split(' ')
    },
    servicePlanningId() {
      return this.$store.state.servicePlannings.servicePlanningId
    },
    routes() {
      return [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          breadcrumbName: 'Planeación de Servicios',
          aIcon: '',
          name: 'service_plannings',
        },
        {
          breadcrumbName: 'Recepción del Servicio',
          aIcon: '',
          name: 'reception_service_plannings',
          path: `/planeacion-servicios-recepcion/${this.$store.state.servicePlannings.servicePlanningId}`,
        },
        {
          breadcrumbName: 'Pesaje del Servicio de Transporte',
          aIcon: '',
        },
      ]
    },
  },
  methods: {
    // Get Catalogs
    // ==
    async getSurveillanceInspectors() {
      this.generalResponse = []
      const params = {
        sort: 'name',
      }
      await this.getResourceData(params, 'users')
      this.surveillanceInspectors = this.generalResponse
      this.generalResponse = []
    },
    async getUms() {
      this.generalResponse = []
      const params = {
        sort: 'name',
        'filter[sap_code]': `${this.UM_SAPCODE}`,
      }
      await this.getResourceData(params, 'ums')
      this.ums = this.generalResponse
      this.generalResponse = []
    },
    // Get Catalogs
    // Surveillance
    OpenWeighingModal() {
      this.weightRegistryForm.ticket = this.ticketStore
      if (this.weightRegistryForm.u_m_s_id === undefined) {
        this.weightRegistryForm.u_m_s_id = this.UM_SAPCODE
      }
      this.editFlag = false
      this.saveAction = ''
      this.$refs['modal-weighing'].show()
    },
    CloseWeighingModal() {
      // pesaje
      this.weightRegistryForm = {
        u_m_s_id: undefined,
        ticket: '',
        date: moment(),
        hour: moment(),
        weight: 0,
        type: 'Entrada',
        vigilant_name: '',
        details: '',
        save_check: true,
      }
      this.editFlag = false
      this.$refs['modal-weighing'].hide()
    },
    async GetData() {
      this.load = true
      const params = {
        sort: 'code',
        'filter[servicePlanningsCarriers]': `${this.carrierID}`,
        include: 'servicePlanningsCarriers,ums',
      }
      await this.getResourceData(params, this.resource)
      if (this.generalResponse.length > 0) {
        this.weightData = this.generalResponse
        this.buildDataSet()
      } else {
        this.weightData = []
      }
      this.generalResponse = []
      this.load = false
    },
    // **************************************************
    // **************************************************
    // Edition Method
    OpenEditableModal(action, data = {}) {
      this.editFlag = true
      this.weightRegistryForm.ticket = data.ticket
      this.weightRegistryForm.weight = parseFloat(data.weight).toFixed(4)
      this.weightType = data.weight_type
      this.weightRegistryForm.vigilant_name = (data.weight_type === 'Salida') ? this.weightForm.entry_vigilant_name : this.weightForm.entry_vigilant_name
      this.weightRegistryForm.details = (data.weight_type === 'Salida') ? this.weightForm.exit_details : this.weightForm.entry_details
      this.weightRegistryForm.u_m_s_id = this.UM_SAPCODE
      this.saveAction = (data.weight_type === 'Salida') ? 'editExit' : 'editEntry'
      this.$refs['modal-weighing'].show()
    },
    // Edition Method
    // **************************************************
    // **************************************************
    buildDataSet() {
      this.dataSet = []
      const assignData = this.weightData[0]
      this.ticketStore = assignData.entry_ticket
      this.weightRegistryForm.u_m_s_id = assignData.ums.jsonApi.id
      const itemEntry = {
        status: (assignData.entry_save_check) ? 'Registrado' : 'Pendiente',
        ticket: assignData.entry_ticket,
        weight: assignData.entry_weight,
        weight_type: 'Entrada',
        dated_at: moment(assignData.entry_dated_at).format('YYYY-MM-DD'),
        hour: assignData.entry_hour,
        status_save: assignData.entry_save_check,
        blocked_check: assignData.blocked_check,
      }
      const itemExit = {
        status: (assignData.exit_save_check) ? 'Registrado' : 'Pendiente',
        ticket: assignData.exit_ticket,
        weight: assignData.exit_weight,
        weight_type: 'Salida',
        dated_at: moment(assignData.exit_dated_at).format('YYYY-MM-DD'),
        hour: assignData.exit_hour,
        status_save: assignData.exit_save_check,
        blocked_check: assignData.blocked_check,
      }
      const itemTotal = {
        status: '-',
        ticket: 'NO APLICA',
        weight: (assignData.exit_weight > assignData.entry_weight) ? (assignData.exit_weight - assignData.entry_weight) : (assignData.entry_weight - assignData.exit_weight),
        weight_type: 'Neto',
        dated_at: '-',
        hour: '-',
        status_save: false,
        blocked_check: assignData.blocked_check,
      }
      // Asignación de los datos en la tabla
      this.dataSet.push(itemEntry)
      this.dataSet.push(itemExit)
      this.dataSet.push(itemTotal)
      // Asignación de los datos en el form
      this.id = assignData.jsonApi.id
      this.weightForm.entry_details = assignData.entry_details
      this.weightForm.entry_vigilant_name = assignData.entry_vigilant_name
      this.weightForm.exit_details = assignData.exit_details
      this.weightForm.exit_vigilant_name = assignData.exit_vigilant_name
      // Asignación de los checks de decisión
      this.entry_save_check = assignData.entry_save_check
      if (assignData.entry_save_check) {
        this.weightType = 'Salida'
      }
      this.exit_save_check = assignData.exit_save_check
      if (this.entry_save_check === true && this.exit_save_check === true) {
        this.showNew = false
        this.blocked_check_record = assignData.blocked_check
      }
    },
    onSubmit() {
      this.$refs['rule-Form'].validate(valid => {
        if (valid) {
          let formObject = {}
          let action = 'post'
          this.relationships.servicePlanningsCarriers.data.id = this.carrierID
          this.relationships.ums.data.id = this.weightRegistryForm.u_m_s_id
          // Assing ticket value
          this.ticketStore = this.weightRegistryForm.ticket
          if (this.id === '0' && this.entry_save_check === false && this.exit_save_check === false) {
            formObject = {
              entry_ticket: `${this.weightRegistryForm.ticket}`,
              entry_dated_at: moment(this.weightRegistryForm.date).format('YYYY-MM-DD'),
              entry_hour: moment(this.weightRegistryForm.hour).format('HH:mm'),
              entry_weight: parseFloat(this.weightRegistryForm.weight).toFixed(4),
              entry_details: `${this.weightRegistryForm.details}`,
              entry_vigilant_name: `${this.weightRegistryForm.vigilant_name}`,
              entry_save_check: true,
              exit_ticket: `${this.weightRegistryForm.ticket}`,
              exit_dated_at: moment().format('YYYY-MM-DD'),
              exit_hour: moment().format('HH:mm'),
              exit_weight: 0,
              exit_details: '',
              exit_vigilant_name: '',
              exit_save_check: false,
              blocked_check: false,
              jsonApi: {
                type: this.resource,
                relationships: this.relationships,
              },
            }
          } else {
            // Update Object
            action = 'patch'
            if (this.saveAction === 'editEntry') {
              formObject = {
                entry_ticket: `${this.weightRegistryForm.ticket}`,
                entry_weight: parseFloat(this.weightRegistryForm.weight).toFixed(4),
                entry_details: `${this.weightRegistryForm.details}`,
                blocked_check: false,
                jsonApi: {
                  type: this.resource,
                  id: this.id,
                  relationships: this.relationships,
                },
              }
            } else if (this.saveAction === 'editExit') {
              formObject = {
                exit_ticket: `${this.weightRegistryForm.ticket}`,
                exit_weight: parseFloat(this.weightRegistryForm.weight).toFixed(4),
                exit_details: `${this.weightRegistryForm.details}`,
                blocked_check: false,
                jsonApi: {
                  type: this.resource,
                  id: this.id,
                  relationships: this.relationships,
                },
              }
            } else {
              formObject = {
                exit_ticket: `${this.weightRegistryForm.ticket}`,
                exit_dated_at: moment(this.weightRegistryForm.date).format('YYYY-MM-DD'),
                exit_hour: moment(this.weightRegistryForm.hour).format('HH:mm'),
                exit_weight: parseFloat(this.weightRegistryForm.weight).toFixed(4),
                exit_details: `${this.weightRegistryForm.details}`,
                exit_vigilant_name: `${this.weightRegistryForm.vigilant_name}`,
                exit_save_check: true,
                blocked_check: false,
                jsonApi: {
                  type: this.resource,
                  id: this.id,
                  relationships: this.relationships,
                },
              }
            }
          }
          this.load = true
          // Reset relationships
          this.resetRelathionships()
          // Envio del Objeto
          this.$store.dispatch(`jv/${action}`, [formObject, {}])
            .then((data) => {
              Modal.success({
                title: '¡Guardado!',
                content: 'La información del pesaje se ha almacenado exitosamente',
                zIndex: 3000,
              })
              this.GetData()
              this.load = false
              this.CloseWeighingModal()
            })
            .catch((errors) => {
              console.log(errors)
              this.load = false
            })
        } else {
          // Fallo de validación
          this.load = false
          return false
        }
      })
    },
    closeWeight() {
      const me = this
      Modal.confirm({
        title: '¿Está seguro de bloquear el pesaje?',
        content: 'Al bloquear el pesaje será imposible editar los registros',
        okText: 'Sí',
        cancelText: 'No',
        okType: 'primary',
        zIndex: 3000,
        onOk() {
          let formObject = {}
          const action = 'patch'
          me.relationships.servicePlanningsCarriers.data.id = me.carrierID
          me.relationships.ums.data.id = me.weightRegistryForm.u_m_s_id
          formObject = {
            blocked_check: true,
            jsonApi: {
              type: me.resource,
              id: me.id,
              relationships: me.relationships,
            },
          }
          me.resetRelathionships()
          me.$store.dispatch(`jv/${action}`, [formObject, {}])
            .then((data) => {
              Modal.success({
                title: '¡Bloqueado!',
                content: 'La información del pesaje se ha bloqueado exitosamente',
                zIndex: 3000,
              })
              me.GetData()
              me.CloseWeighingModal()
            })
            .catch((errors) => {
              console.log(errors)
            })
        },
        onCancel() {
          notification.info({
            message: 'No se ha bloqueado el registro',
          })
        },
      })
    },
    resetRelathionships() {
      this.relationships = {
        servicePlanningsCarriers: {
          data: { id: 0, type: 'servicePlanningsCarriers' },
        },
        ums: {
          data: { id: 0, type: 'ums' },
        },
      }
    },
  },
  async mounted() {
    await this.getUms()
    await this.GetData()
  },
}
</script>

<style scoped>
.border-w-3 {
  border-width:3px !important;
}
.border-w-5 {
  border-width:5px !important;
}
.card-container {
  background: #f5f5f5;
  overflow: hidden;
  padding: 24px;
}
.card-container > .ant-tabs-card > .ant-tabs-content {
  height: 120px;
  margin-top: -16px;
}

.card-container > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
  background: #fff;
  padding: 16px;
}

.card-container > .ant-tabs-card > .ant-tabs-bar {
  border-color: #fff;
}

.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
  border-color: transparent;
  background: transparent;
}

.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
  border-color: #fff;
  background: #fff;
}
</style>
